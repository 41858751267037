/* eslint-disable react/display-name */
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-plugin-sanity-image';
import BlockContent from '@sanity/block-content-to-react';

const ImageContainerStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageStyles = styled.div`
  width: 500px;
`;

const serializers = {
  types: {
    block: (props) => {
      console.log({ props });
      return BlockContent.defaultSerializers.types.block(props);
    },
    span: (props) => BlockContent.defaultSerializers.types.span(props),
    mainImage: ({ node }) => (
      <ImageContainerStyles>
        <ImageStyles>
          <Image asset={node.asset} alt="Our Story" />
        </ImageStyles>
      </ImageContainerStyles>
    ),
  },
  marks: {
    link: ({ mark, children }) => {
      const { blank, href } = mark;
      return blank ? (
        <a
          href={href}
          target="_blank"
          rel="noreferrer noopener"
          style={{ textDecoration: 'underline' }}
        >
          {children}
        </a>
      ) : (
        <a href={href} style={{ textDecoration: 'underline' }}>
          {children}
        </a>
      );
    },
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark;
      const href = `/${slug.current}`;
      return (
        <a href={href} style={{ textDecoration: 'underline' }}>
          {children}
        </a>
      );
    },
  },
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BodyContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default function Page({ data }) {
  const { page } = data;

  return (
    <PageContainer>
      <BodyContainer>
        {page?.body ? (
          <BlockContent
            blocks={page.body}
            serializers={serializers}
            className={page?.name === 'Links' ? 'blockParent' : ''}
            renderContainerOnSingleChild
          />
        ) : (
          <p>Page coming soon 😃</p>
        )}
      </BodyContainer>
    </PageContainer>
  );
}

export const query = graphql`
  query Pages($name: String) {
    page: sanityPage(name: { eq: $name }) {
      name
      slug {
        current
      }
      id
      body: _rawBody
    }
  }
`;
